exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-autres-professionnels-de-sante-jsx": () => import("./../../../src/pages/autres-professionnels-de-sante.jsx" /* webpackChunkName: "component---src-pages-autres-professionnels-de-sante-jsx" */),
  "component---src-pages-cgu-jsx": () => import("./../../../src/pages/cgu.jsx" /* webpackChunkName: "component---src-pages-cgu-jsx" */),
  "component---src-pages-cliniques-et-cabinets-de-groupe-jsx": () => import("./../../../src/pages/cliniques-et-cabinets-de-groupe.jsx" /* webpackChunkName: "component---src-pages-cliniques-et-cabinets-de-groupe-jsx" */),
  "component---src-pages-cpts-ess-jsx": () => import("./../../../src/pages/cpts-ess.jsx" /* webpackChunkName: "component---src-pages-cpts-ess-jsx" */),
  "component---src-pages-demander-une-demo-index-jsx": () => import("./../../../src/pages/demander-une-demo/index.jsx" /* webpackChunkName: "component---src-pages-demander-une-demo-index-jsx" */),
  "component---src-pages-demander-une-demo-merci-jsx": () => import("./../../../src/pages/demander-une-demo/merci.jsx" /* webpackChunkName: "component---src-pages-demander-une-demo-merci-jsx" */),
  "component---src-pages-donnees-personnelles-jsx": () => import("./../../../src/pages/donnees-personnelles.jsx" /* webpackChunkName: "component---src-pages-donnees-personnelles-jsx" */),
  "component---src-pages-hopitaux-jsx": () => import("./../../../src/pages/hopitaux.jsx" /* webpackChunkName: "component---src-pages-hopitaux-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-interoperabilite-index-jsx": () => import("./../../../src/pages/interoperabilite/index.jsx" /* webpackChunkName: "component---src-pages-interoperabilite-index-jsx" */),
  "component---src-pages-interoperabilite-lgc-jsx": () => import("./../../../src/pages/interoperabilite/lgc.jsx" /* webpackChunkName: "component---src-pages-interoperabilite-lgc-jsx" */),
  "component---src-pages-interoperabilite-sih-jsx": () => import("./../../../src/pages/interoperabilite/sih.jsx" /* webpackChunkName: "component---src-pages-interoperabilite-sih-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-medecins-generalistes-jsx": () => import("./../../../src/pages/medecins-generalistes.jsx" /* webpackChunkName: "component---src-pages-medecins-generalistes-jsx" */),
  "component---src-pages-medecins-specialistes-jsx": () => import("./../../../src/pages/medecins-specialistes.jsx" /* webpackChunkName: "component---src-pages-medecins-specialistes-jsx" */),
  "component---src-pages-mentions-legales-jsx": () => import("./../../../src/pages/mentions-legales.jsx" /* webpackChunkName: "component---src-pages-mentions-legales-jsx" */),
  "component---src-pages-tarifs-jsx": () => import("./../../../src/pages/tarifs.jsx" /* webpackChunkName: "component---src-pages-tarifs-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-dynamic-page-jsx": () => import("./../../../src/templates/dynamic-page.jsx" /* webpackChunkName: "component---src-templates-dynamic-page-jsx" */),
  "component---src-templates-info-jsx": () => import("./../../../src/templates/info.jsx" /* webpackChunkName: "component---src-templates-info-jsx" */),
  "component---src-templates-info-post-jsx": () => import("./../../../src/templates/info-post.jsx" /* webpackChunkName: "component---src-templates-info-post-jsx" */),
  "component---src-templates-press-jsx": () => import("./../../../src/templates/press.jsx" /* webpackChunkName: "component---src-templates-press-jsx" */)
}

